<template>
  <div class="about">
    <h5 class="sny-center">Hosted by Marlon "International" ( Email - SalsaNY.info@gmail.com ) <br>and Steve Shaw  ( Email - Salsa@nyc.rr.com ) </h5>
    <p class="sny-center">WITH AT LEAST 75% MAINSTREAM CLASSIC STYLE SALSA MUSIC PLAYED EVERY HOUR. <br>A GUIDE FOR MAMBO SOCIAL DANCERS WHO "BREAK ON 2" </p>
  </div>
</template>

<script>
export default {
  name: 'about',
}
</script>
<style>
.about {
	max-width: 800px;
	margin: 0 auto;
}
</style>