<template>
  <div id="app">

    <Nav />
    <router-view />

    <!-- Footer -->
    <footer class="footer">
        <div class="footer-copyright text-center">© 2020 SalsaNewYork.com</div>
    </footer>
  </div>
</template>

<script>

  require('@/assets/lib/bootstrap-v4.1.3/css/bootstrap.min.css')
  require('@/assets/lib/google-fonts/googleFonts.min.css')
  require('@/assets/lib/flatpickr-v4.5.2/css/flatpickr.min.css')
  require('@/assets/lib/font-awesome/css/font-awesome.min.css')
  require('@/assets/css/styles.scss')

  import Nav from './components/Nav'
  export default {
    name: 'app',
    components: {
      'Nav': Nav
    }
  }
</script>
<style lang="scss">

.footer {
  background-color: var(--color-blue-dk);
  color: var(--color-gray-muted);
  padding: 10px 0;
  font-size: 13px;
}
</style>
