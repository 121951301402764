<template>
  <section>
      <br/>
      <span>[<br/></span>
      <span>&nbsp;&nbsp;'/',<br/></span>
      <span>&nbsp;&nbsp;'/about',<br/></span>
      <span>&nbsp;&nbsp;'/event',<br/></span>
      <span
        v-for="page in filteredPages" 
        :key="page.eventId">
        <span>&nbsp;&nbsp;'/event/{{ page.eventId }}',<br/></span>
      </span>
      <!-- <span
        v-for="event in filteredEvents" 
        :key="event.eventId + event.startDate">
        <span>&nbsp;&nbsp;'/event/{{ event.eventId }}/{{event.startDate}}',<br/></span>
      </span> -->
      <span>]<br/></span>
  </section>
</template>

<script>
import axios from 'axios'
import _sortBy from 'lodash/sortBy'
import utils from '@/utils'

export default {
  name: 'eventList',
  computed: {
    filteredEvents() {
      return _sortBy(this.events, 'eventId')
    },
    filteredPages() {
      return _sortBy(this.eventPages, 'eventId')
    }
  },
  data() {
    return {
      events: [],
      eventPages: []
    }
  },
  methods: {
    async loadEvents() {
      if(this.events.length > 0) return
      const url='https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?output=tsv'
      const that = this
      return await axios
        .get(url)
        .then(function(response) {
           var json = utils.tsvToJSON(response.data)
           that.events =json
        })
        .catch(function() {
           that.events = []
        })
    },
    async loadPages() {
      if(this.eventPages.length > 0) return
      const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?gid=0&single=true&output=tsv'
      const that = this;
      return await axios
        .get(url)
        .then(function(response) {
           var json = utils.tsvToJSON(response.data)
           that.eventPages = json
        })
        .catch(function() {
           that.eventPages = []
        })
    },
  },
  async mounted() {
    await this.loadEvents();
    await this.loadPages();
  },
}
</script>
