<template>
  <div :class="{today:isToday, old:isOld, inactive:inactive}">
    <h5>{{ title }}</h5>
    <event v-for="event in allEvents"
      :startDate="event.startDate"
      :title="event.name"
      :price="event.price"
      :location="event.location"
      :neighborhood="event.neighborhood"
      :level="event.level"
      :data="event"
      :cancelled="event.cancelled"
      :id="event.eventId"
      :key="event.eventId + event.startDate"></event>
  </div>
</template>

<script>
import Event from '@/components/Event.vue'

export default {
  name: 'Day',
  props: {
    events: Array,
    staticEvents: Array,
    day: String,
    inactive: Boolean,
    title: String,
    type: String,
    isToday: Boolean,
    isOld: Boolean
  },
  components: {
    Event
  },
  computed: {
    allEvents() {
      let list = this.events.concat(this.staticEvents);
      return list.sort()
    }
  },
  methods: {
    getCoords(elem) {
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top  = box.top +  scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    },
  },
  // mounted() {
  //   console.log(this.allEvents)
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
