<template>
    <nav class="main-nav">
      <div class="main-nav-inner">
        <a href="/">
            <img class="sny-logo img-fluid" src="/sny-logo.svg" height="16" width="200" alt="Salsa New York Calendar Logo" />
        </a>
        <div class="mobile-only">
            <div id="hamburger-icon" v-on:click='isOpen = !isOpen' v-bind:class="{ open: isOpen}">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
        </div>
        <ul class="desktop-nav desktop-only">
            <!-- <li>
                <a href="/">Home</a>
            </li> -->
             <li class="cta">
              <a href="/list-class">List your online class</a>
            </li>
            <li>
              <a v-on:click='chimpOpen = !chimpOpen'>Newsletter</a>
            </li>
            <li>
              <a href="/volunteer">Join the team</a>
            </li>
            <li>
                <a href="/contact">Contact</a>
            </li>
        </ul>
      </div>
      <div class="mobile-menu" v-show="isOpen">
          <ul>
            <li>
                <a href="/">Home</a>
            </li>
            <li class="cta">
              <a href="/list-class">List your online class</a>
            </li>
            <li>
              <a v-on:click='chimpOpen = !chimpOpen' class="newsletter">Newsletter</a>
            </li>
            <li>
              <a href="/volunteer">Join the team</a>
            </li>
            <li>
                <a href="/contact">Contact</a>
            </li>
        </ul>
      </div>
      <div id="mc_embed_signup" v-show="chimpOpen" class="animated slideInUp">
        <form action="https://gmail.us5.list-manage.com/subscribe/post?u=1a9a3d6785e656b29dea7ab3c&amp;id=bbcf47daf7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">
          <i class="fas fa-times" v-on:click='chimpOpen = false'></i>
          <h2>Salsa New York Newsletter</h2>
          <h3>Sign up for tips, discounts, and more!</h3>
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address </label>
            <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_1a9a3d6785e656b29dea7ab3c_bbcf47daf7" tabindex="-1" value=""></div>
          <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
          </div>
        </form>
      </div>
    </nav>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
          isOpen: false,
          chimpOpen: false
    }
  },
  watch: {
    isOpen: function() {
      var body = document.body;
      this.isOpen ? body.style.position = "fixed" : body.style.position = "initial";
    }
  }
}
</script>

<style lang="scss">
.main-nav {
	border-bottom: 1px solid #eee;
}
.main-nav-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
}
ul.desktop-nav  {
	margin-bottom: 0;
}
.desktop-nav {
  li {
    list-style: none;
    margin-left: 1rem;
  }
  li a,
  li {
    font-family: var(--default-font);
    color: var(--color-blue-dk);
    font-size: 0.8125rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }
  li.cta a {
    padding: 10px;
    color: var(--color-orange);
    font-weight: 900;
  }
  li a:hover,
  li:hover,
  li.cta a:hover {
    color: var(--color-seagreen);
    text-decoration: none;
  }
}
.mobile-menu {
  background-color: black;
  height: 105vh;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  ul {
    margin: 50px auto;
    width: 350px;
  }
  ul li {
    list-style-type: none;
    max-width: calc(100% - 20%);
    padding: 20px 0px;
  }
  ul li a,
  li a.newsletter {
    color: white;
    font-size: 20px;
    padding: 20px;
  }
  li a:hover,
  li.cta a:hover,
  li a.newsletter:hover  {
    color: var(--color-seagreen);
    text-decoration: none;
  }
  li a.newsletter:hover {
    cursor: pointer;
  }
  li.cta a {
    padding: 10px;
    color: var(--color-orange);
    padding: 20px;
  }
  .fas.fa-times {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 35px;
    margin: 10px;
  }
}
#hamburger-icon {
  width: 36px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 3;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: #96a1b0;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }
  span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }

  span:nth-child(1),
  span:nth-child(2) {
    top: 0px;
  }

  span:nth-child(3),
  span:nth-child(4) {
    top: 10px;
  }

  span:nth-child(5),
  span:nth-child(6) {
    top: 20px;
  }
  &.open span:nth-child(1),
  &.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.open span:nth-child(2),
  &.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 5px;
    top: 2px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 2px;
  }

  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: 5px;
    top: 10px;
  }

  &.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 10px;
  }
}

#mc_embed_signup {
  background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;
  width: 350px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  font-family: 'Montserrat', sans-serif;
  -webkit-box-shadow: -5px -5px 12px -2px rgba(0,0,0,0.55);
  -moz-box-shadow: -5px -5px 12px -2px rgba(0,0,0,0.55);
  box-shadow: -5px -5px 12px -2px rgba(0,0,0,0.55);
  .button {
    background-color: var(--color-blue-dk) !important;
    border-radius: 0 !important;
    font-size: 13px !important;
  }
  .mc-field-group {
    padding: 0 0 20px 0;
  }
  .mc-field-group label {
    color: grey;
  }
  .fas {
    font-size: 25px;
  }
  .fa-times {
    position: absolute;
    right: 15px;
    top: 5px;
  }
  h2 {
    color: var(--color-blue-dk);
  }
  h3 {
    text-transform: initial;
    text-align: left;
    color: var(--color-seagreen);
    font-size: 13px;
    padding-bottom: 15px;
  }
}
@media (min-width: 992px) {
  #mc_embed_signup {
    width: 450px;
  }
  .main-nav-inner {
    padding: 15px 0px;
  }
}
</style>
