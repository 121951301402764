<template>
  <div class="404">
    <h1 class="sny-center">Page not found</h1>
    <p class="sny-center"><a href="/">Back to the calendar</a></p>
  </div>
</template>

<script>
export default {
  name: 'pageNotFound',
}
</script>