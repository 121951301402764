<template>
  <router-link class="event" :class="{cancelled:cancelled=='yes',is24_7:is24_7}" :to="{ name: 'eventPageOccurrence', params: { id: id, date: startDate }}">
    <span class="time">{{ time }}</span>
    <span class="title">{{ title }}</span>
    <span class="details">
      <span class="price">{{ dollars }} </span>
      <span v-if="shortAddress" class="dot"></span>
      <span class="location"> {{ shortAddress }}</span>
    </span>
  </router-link>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Event',
  props: {
    startDate: {
        type: String
    },
    title: {
        type: String
    },
    id: {
      type: String
    },
    price: {
      type: String
    },
    neighborhood: {
      type: String
    },
    // level: {
    //   type: String
    // },
    cancelled: {
      type: String
    }
  },
  data() {
    return {
      moment: moment(this.startDate, "YYYY-MM-DD HH:mm")
      //moment: moment.utc(this.startDate || new Date(), "YYYY-MM-DD HH:mm").local()  // For classes (local time)
    }
  },
  computed: {
    hours() {
        return this.moment.format("h");
    },
    minutes() {
        return this.moment.format("mm");
    },
    meridiem() {
        return this.moment.format("A").toLowerCase();
    },
    is24_7() {
      return this.startDate == "24_7"
    },
    time() {
        if(this.is24_7) return "*"
        return this.hours +
        ((this.minutes!=='00')?':' + this.minutes: '') +
        this.meridiem.slice(0,1); // a|p instead of am|pm
    },
    dollars() {
      return (this.price==0)?"free":"$" + this.price.replace(/\.0+$/,'')
    },
    shortAddress() {
      return (this.neighborhood.trim()!="#N/A")?this.neighborhood:""
    },
    // classLevel() {
    //   return this.level.substring(2)
    // }
  },
  // mounted() {
  //   console.log(this.startDate)
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.details {
  display: block;
}
</style>
