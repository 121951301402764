const state = {
    agendaRange: {
      start: '',
      end: ''
    },
    calendarSelectedDate: '',
    eventPages: [],
    events: []
}

export default state;