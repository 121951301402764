<template>

  <nav class="sny-nav" id="calendar-header">

    <div v-if="type==='calendar'" class="calendar-nav">
        <div>
          <button class="btn-arrow left"  @click="$emit('prev')" v-if="showBackBtn"></button>
          <h4 v-if="title">{{ title }}</h4>
          <button class="btn-arrow right" @click="$emit('next')"></button>
        </div>
        <div>
          <button class="btn-txt"  @click="$emit('now')" :disabled="disableTextBtn">Today</button>
        </div>
    </div>

    <div v-if="type==='agenda'" class="dates">
      <div>
        <span class="sny-txt-white">From: </span>
        <flat-pickr
          :value="start"
          placeholder="start"
          :config="config"
          @input="$emit('changeStart', $event)"></flat-pickr>
      </div>
      <div>
        <span class="sny-txt-white">To: </span>
        <flat-pickr
          :value="end"
          placeholder="end"
          :config="config"
          @input="$emit('changeEnd', $event)"></flat-pickr>
      </div>
    </div>
  </nav>
</template>

<script>
import store from '@/store'
import flatPickr from 'vue-flatpickr-component';


export default {
  components: {
      flatPickr
  },
  computed: {
    range() {
      return store.getters.agendaRange || {}
    }
  },
  data() {
    return {
      config: {
       altFormat: "M d, Y",
       altInput: true,
     },
    }
  },
  name: 'CalendarNav',
  props: {
    title: String,
    showBackBtn: Boolean,
    disableTextBtn: Boolean,
    type: String,
    start: String,
    end: String
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sny-nav {
  background: var(--color-blue-dk);
  padding: 10px;
  .calendar-nav {
    display: flex;
    justify-content: space-between;
  }
  h4 {
    color: white;
    font-size: 16px;
    display: inline-block;
    text-align: center
  }
  .btn-txt {
    -webkit-appearance: none;
    border: none;
    background: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  .btn-txt:disabled {
    opacity: .65;
    box-shadow: none;
    cursor: default;
  }
  .btn-arrow:after {
      font-weight: 700;
      font-size: 200%;
      top: -7%;
  }
  .btn-arrow {
      height: 1em;
      line-height: 1em;
      font-size: 1em;
      font-family: "Courier New",Courier,monospace;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: white;
      background: none;
      border: none;
      cursor: pointer;
  }
  .btn-arrow.right:after {
      content: "\203A";
  }
  .btn-arrow.left:after {
      content: "\2039";
  }
  .dates {
    display: flex;
    padding-left: 15px;
    justify-content: space-between;
    width: calc(100% - 15px);
  }
}
</style>
