<template>
  <div class="event">
    <Loader v-if="loading" />
    <div v-else class="full-width">
      <section class="container event-title pt-5 pb-3 pl-0 visible-lg-up">
          <div class="row">
            <!-- <i class="far fa-calendar cal-icon"></i> -->
            <div class="cal-icon">
              <header></header>
                <span class="day" v-if="occurrence.startDate!='24_7'">{{ dateDD(occurrence.startDate) }}</span>
                <span class="month" v-if="occurrence.startDate!='24_7'">{{ dateMMM(occurrence.startDate) }}</span>
                <span class="day" v-if="occurrence.startDate=='24_7'"><i class="fas fa-redo-alt"></i></span>
                <span class="month" v-if="occurrence.startDate=='24_7'">24/7</span>
            </div>
            <div class="col">
              <h4 class="event-date" v-if="occurrence.startDate!='24_7'">{{dateDDDDMMMMDDYYY(occurrence.startDate)}}</h4>
              <h4 class="event-date" v-if="occurrence.startDate=='24_7'">Event available at all times.</h4>
              <h1 class="mb-3">
              {{occurrence.name}}
              </h1>
            </div>
          </div><!-- end row  -->
          <div class="logo-host-container" v-if="occurrence.eventLogo">
            <img class="img-fluid" alt="Placeholder" :src="occurrence.eventLogo">
            <div>
              <p v-if="occurrence.organizer">Hosted by: <a href="#">{{occurrence.organizer}}</a></p>
            </div>
          </div><!-- end row  -->
      </section>
      <section class="container-fluid event-header" v-if="occurrence.flyerImage">
        <div class="row">
          <div class="col pr-0 pl-0 bg-black">
            <!-- <img v-if="!eventPhoto" class="img-fluid" alt="Placeholder" style="width: 100%; height: 250px;" src="../assets/images/salsa-dancing.jpg">
            <img v-else class="img-fluid" alt="Placeholder" style="width: 500px; height: auto;" :src="eventPhoto"> -->
            <img class="header-image" alt="Placeholder" :src="occurrence.flyerImage">
          </div>
        </div><!-- end row  -->
      </section>
      <section class="container event-title visible-md-down">
          <div class="row">
            <div class="col">
              <h4 class="event-date">{{dateDDDDMMMMDDYYY(occurrence.startDate)}}</h4>
              <h1 class="mb-3">
              {{occurrence.name}}
              </h1>
            </div>
          </div><!-- end row  -->
          <div class="logo-host-container" v-if="occurrence.eventLogo">
            <img class="img-fluid" alt="Placeholder" :src="occurrence.eventLogo">
            <div>
              <p v-if="occurrence.organizer">Hosted by: <a href="#">{{occurrence.organizer}}</a></p>
            </div>
          </div>
      </section>
      <hr class="visible-md-down">
      <section class="container event-info">
        <div class="row event-info-main">
          <div class="col-xs-12 col-sm-6 col-xl-4" v-if="occurrence.frequency">
            <div class="icon-left">
              <i class="far fa-clock"></i>
            </div>
            <div class="icon-text-right">
              <p>{{dateDDDDMMMMDDYYY(occurrence.startDate)}}</p>
              <p>{{ startDate.format("h:mm a") }} - {{ endDate.format("h:mm a") }}</p>
              <p class="muted">{{ occurrence.frequency }}</p>
              <!-- <p><a href="#">Add to calendar</a></p> -->
            </div>
            <div class="col-12 separator"></div>
          </div>
          <div class="col-xs-12 col-sm-6 col-xl-4" v-if="occurrence.locationName">
            <div class="icon-left">
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="icon-text-right">
              <p>{{occurrence.locationName}}</p>
              <p class="muted"><a :href="locationLink" target="_blank">{{occurrence.locationAddress}}</a></p>
            </div>
            <div class="col-12 separator"></div>
          </div>
          <div class="col-12 col-xl-4" v-if="occurrence.price">
            <div class="icon-left">
              <i class="fas fa-money-bill-wave"></i>
            </div>
            <div class="icon-text-right">
              <p>{{dollars}}&nbsp;&nbsp;</p>
              <p class="red">{{occurrence.priceDetails}}</p>
            </div>
            <div class="col-12 separator"></div>
          </div>
        </div><!-- end row -->
        <div class="row event-info-main modal-parent">
          <div class="col-xs-12 col-xl-12" v-if="occurrence.description">
            <div class="icon-left">
              <i class="far fa-file-alt"></i>
            </div>
            <div class="icon-text-right" >
              <p><span v-html="occurrenceDescription"></span>  <span class="red" v-if="occurrence.tips"> SNY Tip: {{occurrence.tips}}</span></p>
              <!-- <div @click="openModal" class="sny-link visible-lg-down">SNY tip: {{occurrence.tips}}</div> -->
            </div>
            <div class="col-12 separator"></div>
          </div>
          <!-- Show only on desktop -->
          <!-- <div class="col-xl-4 visible-xl"  v-if="occurrence.tips">
            <div class="icon-left">
              <i class="far fa-lightbulb"></i>
            </div>
            <div class="icon-text-right" >
              < ! - - <div @click="openModal" class="sny-link visible-lg-down">SNY tip: {{occurrence.tips}}</div> - -  >
            </div>
            <div class="col-12 separator"></div>
          </div> -->
          <!-- Modal -->
          <!-- <div v-if="showModal" @click="closeModal = false" class="modal">
            <p>Want more tips? Sign up for the Salsa New York mailing list for tips, discounts, and special announcements.</p>
            <input type="text" class="form-control" aria-label="email">
            <button type="button" class="btn btn-submit" @click="showModal = false">Submit</button>
          </div> -->
        </div><!-- end row -->
        <div class="row event-info-main">

          <div class="col-xs-12 col-sm-4" v-if="occurrence.level">
            <div class="icon-left">
              <i class="fas fa-trophy"></i>
            </div>
            <div class="icon-text-right">
              <p>{{occurrence.level.substring(2)}}</p>
            </div>
            <div class="col-12 separator"></div>
          </div>
          <div class="col-xs-12 col-sm-4" v-if="occurrence.djName">
            <div class="icon-left">
              <i class="fas fa-microphone"></i>
            </div>
            <div class="icon-text-right">
              <p>{{occurrence.djName}}</p>
            </div>
            <div class="col-12 separator"></div>
          </div>
          <div class="col-xs-12 col-sm-4" v-if="occurrence.floor">
            <div class="icon-left">
              <i class="fas fa-shoe-prints"></i>
            </div>
            <div class="icon-text-right">
              <p>{{occurrence.floor}}</p>
            </div>
            <div class="col-12 separator"></div>
          </div>
          <div class="col-xs-12 col-sm-4" v-if="occurrence.ages">
            <div class="icon-left">
              <i class="fas fa-id-card"></i>
            </div>
            <div class="icon-text-right">
              <p>{{occurrence.ages}}</p>
            </div>
            <div class="col-12 separator"></div>
          </div>
          <div class="col-12" v-if="occurrence.website || occurrence.facebook || occurrence.instagram">
            <div class="icon-left">
            <i class="fas fa-link"></i>
            </div>
            <div class="icon-text-right">
              <p><a v-if="occurrence.website" :href="occurrence.website" target="_blank">{{occurrence.website}}</a></p>
              <p><a v-if="occurrence.facebook" :href="occurrence.facebook" target="_blank">{{occurrence.facebook}}</a></p>
              <p><a v-if="occurrence.instagram" :href="occurrence.instagram" target="_blank">{{occurrence.instagram}}</a></p>
            </div>
            <div class="col-12 separator"></div>
          </div>
        </div><!-- end row -->
        <div class="row event-info__additional">
          <!-- <div class="col-xs-12 col-md-6">
            <div class="map" v-if="occurrence.location">
              <a :href="locationLink" target="_blank">
                <img alt="map" :src="'https://www.salsanewyork.com/images/locations/'+occurrence.location+'.png'">
              </a>
            </div>
          </div> -->

          <div class="col-xs-12 col-md-6" v-if="occurrence.eventVideo">
            <div class="yt-video">
              <iframe width="100%" height="100%" :src="occurrence.eventVideo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
          </div>
        </div><!-- end row -->
        <!-- <div class="row event-info-main">
          <div class="col-12" v-if="occurrence.djName">
            <div class="icon-left">
              <i class="far fa-calendar-plus"></i>
            </div>
            <div class="icon-text-right">
              <p>See more events by: <a href="#">{{occurrence.organizer}}</a></p>
            </div>
            <div class="col-12 separator"></div>
          </div>
        </div> -->
      </section>
      <!-- <section class="container other">
        <h4>Other events you might enjoy:</h4>
        <div class="other-events">
          <div class="other-event">
            <div class="icon-left">
              <div class="logo"></div>
            </div>
            <div class="icon-text-right">
              <p>Friday, November 16 2019</p>
              <p><a href="#">Latin Sundays Party with Jimmy Anton</a></p>
              <p></br>Manhattan Salsa Social</br></p>
            </div>
            <div class="separator"></div>
          </div>
          <div class="other-event">
            <div class="icon-left">
              <div class="logo"></div>
            </div>
            <div class="icon-text-right">
              <p>Friday, November 16 2019</p>
              <p><a href="#">Latin Sundays Party with Jimmy Anton</a></p>
              <p></br></br>Manhattan Salsa Social</br></p>
            </div>
            <div class="separator"></div>
          </div>
          <div class="other-event">
            <div class="icon-left">
              <div class="logo"></div>
            </div>
            <div class="icon-text-right">
              <p>Friday, November 16 2019</p>
              <p><a href="#">Latin Sundays Party with Jimmy Anton</a></p>
              <p></br></br>Manhattan Salsa Social</br></p>
            </div>
            <div class="separator"></div>
          </div>
        </div>
      </section> -->
      <section>
          <AddThis
            publicId="ra-5d93e16bea826417"
            :data-title="occurrence.name"
            :data-description="occurrence.description"
            :data-media="occurrence.eventLogo"
          />
      </section>
    </div>
  </div>
  <!-- <div class="col-12" v-if="performers">
  <div class="icon-left">
    <i class="fas fa-users"></i>
  </div>
  <div class="icon-text-right">
    <p>Performances</p>
    <p v-html="performers"></p>
  </div>
  <div class="col-12 separator"></div>
  </div> -->
</template>

<script>
import axios from 'axios'
import _filter from 'lodash/filter'
import _merge from 'lodash/merge'
import moment from 'moment'
import utils from '@/utils'
import Loader from '@/components/Loader.vue'
import AddThis from 'vue-simple-addthis-share'
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'eventPageOccurrence',
  computed: {
    ...mapGetters([
      'eventPages',
      'events',
    ]),
    id () {
      return this.$route.params.id
    },
    date () {
      return this.$route.params.date
    },
    page () {
      return _filter(this.eventPages, { 'eventId': this.id })[0]
    },
    event () {
      return _filter(this.events, { 'startDate': this.date, 'eventId': this.id  })[0]
    },
    occurrence () {
      return _merge(this.page, this.event)
    },
    startDate () {
      return new moment(this.occurrence.startDate)
    },
    endDate () {
      return new moment(this.occurrence.endDate)
    },
    locationLink () {
      return "https://maps.google.com/?q=" + this.occurrence.locationName + " " + this.occurrence.locationAddress
    },
    eventPhoto () {
      return this.occurrence.eventLogo
    },
    performers () {
      if(!this.occurrence.performers) return
      return this.occurrence.performers.replace(/(?:;)/g, '<br />');
    },
    dollars() {
      if(!this.occurrence.price) return
      return (this.occurrence.price==0)?"free":"$" + this.occurrence.price.replace(/\.0+$/,'')
    },
    occurrenceDescription () {
      if(!this.occurrence.description) return
      return this.page.description.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1">$1</a>');
    }
  },
  components: {
    Loader,
    AddThis
  },
  data() {
    return {
      showMoreText: true,
      loading: true,
      showModal: false
    }
  },
  methods: {
    ...mapMutations([
      'setEventPages',
      'setEvents'
    ]),

    // Using 1 souce only for all data
    // async loadPages() {
    //   if(this.eventPages.length > 0) return
    //   const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?gid=0&single=true&output=tsv'
    //   const that = this;
    //   return await axios
    //     .get(url)
    //     .then(function(response) {
    //        var json = utils.tsvToJSON(response.data)
    //        that.setEventPages(json)
    //     })
    //     .catch(function() {
    //        that.setEventPages([])
    //     })
    // },
    async loadEvents() {
      if(this.events.length > 0) return


      // Using 1 souce only for all data

      // Classes Online tab
      const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?gid=0&single=true&output=tsv'

      // Events tab
      //const url='https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?output=tsv'
      const that = this
      return await axios
        .get(url)
        .then(function(response) {
          var json = utils.tsvToJSON(response.data)
          that.setEvents(json)
          that.setEventPages(json)
        })
        .catch(function() {
          that.setEvents([])
          that.setEventPages([])
        })
    },
    dateDDDDMMMMDDYYY(date) {
      return moment(date).format("dddd, MMMM DD YYYY")
    },
    dateDDDDMMMDD(date) {
      return moment(date).format("dddd, MMM DD")
    },
    dateDD(date) {
      return moment(date).format("DD")
    },
    dateMMM(date) {
      return moment(date).format("MMM")
    },
    timehhmmss(time) {
      var index = time.indexOf(":")
      var hours = parseInt(time.substring(0, index))
      return (hours < 10 ? "0" : "") + time
    },
    openModal () {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    }
  },
  destroyed() {
    document.body.classList.remove("sny-no-scroll")
  },
  async mounted() {
    await this.loadEvents()

    // if the event doesn't exist redirect to general page
    if (!this.event) {
      //this.$router.push({name:'eventPage'})
      this.$router.push({name:'home'})
    } else {

      // Using 1 souce only for all data
      //await this.loadPages();

      // if the page doesn't exist redirect to calendar
      if (!this.page) {
        this.$router.push({name:'home'})
      }
      this.loading = false
    }

  },
  metaInfo () {
    return {
      title: this.occurrence.name || 'Events',
      // meta: [
      //   { vmid: 'description',
      //     name: 'description',
      //     content: this.occurrence.description || 'Best Salsa Events in NY',
      //   },
      //   { name: 'og:description', content: this.occurrence.description },
      //   {
      //     property: "og:image",
      //     content: this.occurrence.flyerImage || "https://www.salsanewyork.com/salsa-new-york-square.png"
      //   }
      // ],
      titleTemplate: '%s | SalsaNewYork Calendar',
    }
  }
}



</script>


<style scoped lang="scss">
.bg-black {
  background: black;
}
.map > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5625rem;
  font-weight: bold;
  color: var(--color-blue-dk);
  padding-top: 0;
}
h4 {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-blue-dk);
}
p {
  font-family: 'Raleway', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--color-blue-dk);
  margin-bottom: 0;
  overflow-wrap: break-word;
}
p a {
  color: var(--color-blue-link);
}
p.muted {
  color: var(--color-gray-muted);
}
p.red,
p span.red {
  color:  var(--color-red);
}
.separator {
  display: block;
  margin-top: 20px;
}
.visible-lg-up {
  display: none;
}
.visible-xl {
  display: none;
}
.visible-md-down {
  display: block;
}
.visible-lg-down {
  display: block;
}
.icon-left {
  float: left;
}
.icon-text-right {
  padding: 0 0 0 35px;
}
.event-header {
  .header-image {
    object-fit: contain;
    width: 100%;
    height: 30vh;
  }
}
.event-title{
  position: relative;
  .event-date {
    margin-bottom: 0;
    padding: 15px 0 5px 3px;
  }
  .logo-host-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  img {
    height: 90px;
    width: 90px;
    padding: 0 10px 10px 0;
  }
}
.event-info {
  .event-info-main {
    padding: 0 25px;
  }
  .map {
    height: 265px;
    background-color: #e1e4e6;
    margin-bottom: 20px;
  }
  .yt-video {
    height: 265px;
    background-color: #e1e4e6;
    margin-bottom: 20px;
  }
}
.other {
  .other-events {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  h4 {
    padding-bottom: 16px;
    // text-align: center;
  }
  .logo {
    height: 60px;
    width: 60px;
    background-color: #e1e4e6;
    border-radius: 50%;
  }
  .icon-text-right {
    padding: 0 0 0 75px;
  }
}
.modal-parent {
  position: relative;
    .modal {
    background: #fff;
    height: 220px;
    width: calc(100% - 20%);
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0 auto;
    top: 95%;
    text-align: left;
    padding: 20px;
    z-index: 1;
    border: 2px solid #eee;
    border-radius: 4px;
    overflow: inherit;
  }
  .modal:after,
  .modal:before {
      content: '';
      display: block;
      position: absolute;
      right: 70%;
      width: 0;
      height: 0;
      border-style: solid;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
  }
  .modal:after {
      top: -19px;
      border-color: transparent transparent transparent #fff;
      border-width: 10px;
  }
  .modal:before {
      top: -22px;
      border-color: transparent transparent transparent #eee;
      border-width: 10px;
  }
  input {
    margin: 10px 0;
  }
  .btn {
    font-family: 'Raleway', sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0;
    overflow-wrap: break-word;
    background-color: var(--color-blue-link);
    color: #fff;
    width: 100px;
  }
}

.cal-icon {
  position: absolute;
  top: 25%;
  left: -12%;
  width: 4em;
  height: 4em;
  background-color: #fff;
  border-radius: 0.6em;
  display: flex;
  text-align: center;
  border: 5px solid var(--color-blue-dk);

  header {
    position: absolute;
    top: 0%;
    padding: 4px 0;
    width: 100%;
    background-color: var(--color-blue-dk);
  }
  span.day {
    font-weight: 600;
    width: 100%;
    font-size: 20px;
    letter-spacing: -0.05em;
    padding-top: 9px;
    color: var(--color-red);
  }
  span.day >i {
    color: var(--color-red);
  }
  span.month {
    position: absolute;
    bottom: 0%;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    width: 100%;
    color: var(--color-blue-dk);
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .container {
    max-width: 640px;
  }
  .event {
    // background-color: rgba(243, 34, 173, 0.37);
  }
  .event-header .header-image {
    height: 50vh;
  }
  .modal-parent .modal {
    height: 200px;
    right: 15%;
    width: calc(100% - 40%);
  }
  .modal-parent .modal:after,
  .modal-parent .modal:before {
      left: 10%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .event {
    // background-color: rgba(226, 248, 211, 0.856);
  }
  .container {
    max-width: 720px;
  }
  .event-header .header-image {
    height: 60vh;
  }
  .other {
    .other-events {
      flex-direction: row;
      text-align: center;
    }
    h4 {
      text-align: center;
    }
    .icon-text-right {
      padding: 0;
    }
    .icon-left {
      float: none;
      display: flex;
      justify-content: center;
    }
  }
  .modal-parent .modal {
    height: 190px;
    right: 30%;
    width: calc(100% - 50%);
  }
  .modal-parent .modal:after,
  .modal-parent .modal:before {
      left: 10%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .event {
      // background-color: rgba(0, 119, 128, 0.281);
    }
    .container {
      max-width: 750px;
    }
    .visible-lg-up {
      display: block;
    }
    .visible-md-down {
      display: none;
    }
    .event-header {
      max-width: 750px;
      margin: auto;
      padding: auto;
      margin-bottom: 30px;
      .header-image {
        border-radius: 4px;
      }
    }
    .other-events {
      .logo {
          height: 80px;
          width: 80px;
      }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .event {
    // background-color: rgba(55, 0, 255, 0.151);
  }
  .container, .event-header {
    max-width: 1000px;
  }
  .cal-icon {
    left: -8%;
  }
  .modal-parent .modal {
    height: 200px;
    left: 70%;
    top: 40%;
    width: calc(80% - 40%);
  }
  .modal-parent .modal:after,
  .modal-parent .modal:before {
      left: 10%;
  }
  .visible-xl {
    display: block;
  }
  .visible-lg-down {
    display: none;
  }
}
</style>
