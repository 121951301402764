const mutations = {
    updateAgenda: (state, value) => {
        state.agendaRange = value
    },
    updateCalendar: (state, value) => {
        state.calendarSelectedDate = value
    },
    setEvents: (state, value) => {
        state.events = value
    },
    setEventPages: (state, value) => {
        state.eventPages = value
    }
}
export default mutations;