<template>
  <div class="home">
    <div class="banner-section mb-4">
      <div class="overlay"></div>
      <h3>Calendar of New York City <br> Metro Area <span>Salsa Dancing</span> <br> Events and <span>Online Classes</span></h3>
      <p class="mt-4 mb-4 mr-3 ml-3 filters">
          <span>Show me events in </span>
          <multiselect
            v-model="value"
            name="level"
            :options="neigborhoods"
            :taggable="true"
            :select-label="'Select'"
            :multiple="true"
            :block-keys="['Tab', 'Enter']"
            placeholder="All neigborhoods"></multiselect>
      </p>
    </div>
    <div>
      <p class="center" v-if="location">
        Event's time zone: <strong>{{ location }}, {{ time }}</strong>.
      </p>
    </div>
    <section v-if="sortedEvents.length < 1">
      <Loader />
      <div class="note"><h2>Loading salsa events</h2></div>
    </section>
    <section v-else>
      <Calendar v-if="fullWidth > 700" :events="sortedEvents" :scrollTop="scrollTop" />
      <Agenda  v-if="fullWidth <= 700" :events="sortedEvents" :scrollTop="scrollTop"/>
    </section>

    <div class="p-3"></div>
  </div>
</template>

<script>
import axios from 'axios'
import Agenda from '@/components/Agenda.vue'
import Calendar from '@/components/Calendar.vue'
import Loader from '@/components/Loader.vue'
import utils from '@/utils'
import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex';
import Multiselect from 'vue-multiselect'
import moment from 'moment-timezone'


export default {
  name: 'home',
  components: {
    Agenda, Calendar, Loader, Multiselect
  },
  computed: {
    ...mapGetters([
      'events'
    ]),
    neigborhoods () {
      var temp = []
      for(var i in this.events) {
        if(this.events[i]['neighborhood']!="") {
          temp.push(this.events[i]['neighborhood'])
        }
      }
      return _.uniq(temp.sort())
    },
    levels () {
      var temp = []
      for(var i in this.events) {
        if(this.events[i]['level']!="") {
          temp.push(this.events[i]['level'])
        }
      }
      temp.sort()
      for(var j in temp) {
        if(temp[j]) {
          temp[j] = temp[j].substring(2)
        }
      }
      return _.uniq(temp)
    },
    sortedEvents () {
      var temp = []
      var that = this
      if(_.isEmpty(that.value)) {
        temp = _.sortBy(that.events, 'startDate')
      }
      else {
        temp = _.filter(that.events, function(o) {
          return _.includes(that.value, o.neighborhood);
        });
      }
      return temp
    },
    location () {
      return moment.tz.guess();
    },
    time () {
      return moment(this.currentDate).tz(moment.tz.guess()).format('LT z');
    }
  },
  data() {
   return {
     fullWidth: document.documentElement.clientWidth || 0,
     scrollTop : 0,
     applyFilters: false,
     value: [],
     currentDate: null,
     dateInterval: null
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.updateScroll)
    clearInterval(this.dateInterval);
  },
  methods: {
    ...mapMutations([
      'setEvents',
      'setEventPages'
    ]),
    handleResize () {
      this.fullWidth = document.documentElement.clientWidth
    },
    async loadEvents() {
      if(this.events.length > 0) return

      // Classes online tab
      //const url='https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?gid=1178567471&single=true&output=tsv'

      // Calendar Tab
      const url='https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?output=tsv'
      const that = this
      return await axios
        .get(url)
        .then(function(response) {
           var json = utils.tsvToJSON(response.data)
           that.setEvents(json)
           that.setEventPages(json)
        })
        .catch(function() {
           that.setEvents([])
           that.setEventPages([])
        })
    },
    updateScroll () {
      const el =document.scrollingElement || document.documentElement
      this.scrollTop = el.scrollTop
    },
    updateDate () {
      this.currentDate = new Date();
    }
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.updateScroll)
    await this.loadEvents()
    this.dateInterval = setInterval(this.updateDate, 1000)
    this.updateDate()
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.p-3 {
  padding: 180px 0!important;
}
.center {
  text-align: center;
}
.note {
  color: var(--color-blue-dk);
  font-size: 13px;
  padding-top: 10px;
  text-align: center;
  width: 100%;
}
.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
  max-width: 300px;
  width: 300px;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  & > span {
    display: block;
    width: 100%;
    text-align: left;
    margin-right: 10px;
    color: #000;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
  }
  .multiselect__tags {
    border-radius: 0;
  }
  @media (min-width: 700px) {
    flex-direction: column;
    & > span {
      margin-right: 0;
    }
  }
}
.banner-section {
  background-image: url("https://s3.amazonaws.com/qa.salsanewyork.com/img/blurred.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  h3 {
    color: white;
    text-align: left;
    padding: 40px 20px 10px;
    position: relative;
    font-size: 20px;
  }
  h3 span {
    color: var(--color-orange);
  }
  .overlay {
    background-color: #000;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    bottom: 0;
  }
  @media (min-width: 700px) {
    flex-direction: row;
    align-items: center;
    height: 40vh;
    h3 {
      font-size: 30px;
      min-width: 60%;
    }
  }
}

</style>
