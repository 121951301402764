'use strict'

import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import Volunteer from './views/Volunteer.vue'
import EventPage from './views/EventPage.vue'
import EventPageRoot from './views/EventPageRoot.vue'
import EventPagesList from './views/EventPagesList.vue'
import EventPageOccurrence from './views/EventPageOccurrence.vue'
import Script from './views/Script.vue'
import Home from './views/Home.vue'
import PageNotFound from './views/PageNotFound.vue'
import ListClass from './views/ListClass.vue'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/list-class',
      name: 'list-class',
      component: ListClass
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/volunteer',
      name: 'volunteer',
      component: Volunteer
    },
    {
      path: '/script',
      name: 'script',
      component: Script
    },
    {
      path: '/event',
      name: 'eventPagesList',
      component: EventPagesList
    },
    {
      path: '/event/:id',
      component: EventPageRoot,
      children: [
        {
          path: '',
          name: 'eventPage',
          component: EventPage
        },
        {
          path: ':date',
          name: 'eventPageOccurrence',
          component: EventPageOccurrence
        },
      ]
    },
    {
      path: "*",
      name: '404',
      component: PageNotFound
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})
export default  router
