<template>
  <div class="sny-center">
    <div
      v-if="filteredEvents.length"
      v-for="event in filteredEvents"
      :key="event.eventId"
      class="full-width" >
      <router-link
        :to="{ name: 'eventPage', params: { id: event.eventId }}">
        {{ event.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _sortBy from 'lodash/sortBy'
import utils from '@/utils'

export default {
  name: 'eventPagesList',
  computed: {
    filteredEvents() {
      return _sortBy(this.events, 'eventId')
    }
  },
  data() {
    return {
      events: []
    }
  },
  methods: {
    async loadEvents() {
       const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?gid=0&single=true&output=tsv'
       const that = this;
       return await axios
        .get(url)
        .then(function(response) {
           var json = utils.tsvToJSON(response.data)
           that.events = json
        })
        .catch(function() {
           that.events = []
        })
    },
  },
  mounted() {
    this.loadEvents();
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'List of Salsa New York Events',
      // all titles will be injected into this template
      titleTemplate: '%s | List of Events',
      meta: [
      //{ vmid: 'events', name: 'description', content: this.events.join() }
      ]
    }
}
</script>
