<template>
  <div class="loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>

export default {
  name: 'Loader',
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loader {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 3px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    width: 100px;
    
    > div {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        background: var(--color-blue-dk);
        transform: scale(0.0);
        transform-origin: center center;
        animation: loader 2s infinite linear;
        
        &:nth-of-type(7) {}
        
        &:nth-of-type(1),
        &:nth-of-type(5), 
        &:nth-of-type(9) {
            animation-delay: 0.4s;
        }
        
        &:nth-of-type(4),
        &:nth-of-type(8) {
            animation-delay: 0.2s;
        }
        
        &:nth-of-type(2),
        &:nth-of-type(6) {
            animation-delay: 0.6s;
        }
        
        &:nth-of-type(3) {
            animation-delay: 0.8s;
        }
    }
}

@keyframes loader {
    0%   { transform: scale(0.0); }
    40%  { transform: scale(1.0); }
    80%  { transform: scale(1.0); }
    100% { transform: scale(0.0); }
}
</style>