<template>
  <div class="agenda">
    <div class="header">
      <calendar-nav
        :start="agendaRange.start"
        :end="agendaRange.end"
        type="agenda"
        @changeStart="updateStart"
        @changeEnd="updateEnd">
      </calendar-nav>
      <button
        class="btn top"
        v-if="isTop"
        @click="scrollToTop">
          <i class="arrow-top"></i>
          Back to top
        </button>
    </div>

    <div class="body">
      <day
        class="day"
        v-for="day in daysInRange"
        :day="day"
        :isToday="isToday(day)"
        :title="dayTitle(day)"
        :events="eventsInRange[day]"
        :staticEvents ="eventsInRange['24_7']"
        :data="day"
        :key="day"
        v-if="eventsInRange[day].length">
      </day>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import Day from '@/components/Day.vue'
import CalendarNav from '@/components/CalendarNav.vue'
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    Day, CalendarNav
  },
  computed: {
    ...mapState([
      'agendaRange'
    ]),
    isTop() {
      return this.scrollTop > 193
    },
    daysInRange() {
      var days = moment(this.agendaRange.end).diff(moment(this.agendaRange.start), 'days');
      var temp = []
      for(var i=0; i<=days; i++) {
        temp.push(moment(this.agendaRange.start).add(i,"days").format(this.dateFormat))
      }
      return temp
    },
    eventsInRange() {
      var temp = {};

      // Create empty matrix with the days of the current month
      for(var i in this.daysInRange) {
        temp[this.daysInRange[i]] = [];
      }
      temp["24_7"] = []


      var date = "";
      // Push events in corresponding day
      for (i in this.events) {
        date = (this.events[i]).startDate

        if (date != "24_7") {
          // Remove the time of the start date 2020-04-18 19:00
          date = moment.utc(date, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD")
        }
        if (date && temp[date]) {
          temp[date].push(this.events[i])
        }
      }
      return temp
    },
  },
  data() {
    return {
      navTitle: "Today",
      dateFormat: "YYYY-MM-DD",
    }
  },
  methods: {
    ...mapMutations(['updateAgenda']),
    dayTitle(day) {
      return this.isToday(day)?"Today":moment(day).format('dddd MMMM DD, YYYY')
    },
    isToday(day) {
      return moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
    },
    updateStart(start) {
      this.updateAgenda({start:start, end:this.agendaRange.end})
      this.validateRange();
    },
    updateEnd(end) {
      this.updateAgenda({start:this.agendaRange.start, end:end})
      this.validateRange();
    },
    validateRange() {

      // Reset range if there is no value
      if (!this.agendaRange.start || !this.agendaRange.end) {
        this.resetRange()
      }
    },
    resetRange() {
      var start, end, newRange = {}
      start = moment(new Date()).format(this.dateFormat)
      end = moment(start).add(7,"days").format(this.dateFormat)
      newRange = {start:start, end:end}
      this.updateAgenda(newRange)
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.validateRange()
  },
  name: 'Agenda',
  props: {
    events: Array,
    scrollTop: Number,
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.agenda {
  font-size: 15px;
  .day {
    position: relative;
    & > h5 {
      background-color: var(--color-gray-lt);
      padding: 15px;
      margin: 0;
      position: sticky;
      top: -1px;
      font-weight: 600;
      font-size: 15px;
    }
    .time {
      font-weight: bold;
      margin-right: 5px;
    }
    .event {
      border-bottom: 1px solid var(--color-gray-lt);
      border-left: 3px solid var(--color-blue-dk);
      display: block;
      margin-bottom: 5px;
      padding: 10px;
      .dot:before { content: "  \00b7  "; }
      .price {
        text-transform: uppercase;
        color: var(--color-gray-muted);
      }
      .location {
        text-transform: capitalize;
        color: var(--color-gray-muted);
      }
      &:last-child {
        border-bottom: none;
      }
      &.cancelled {
        border-left: 3px solid var(--color-red);
        &::after{
          content:"cancelled";
          display: block;
          text-transform: uppercase;
          font-weight: bold;
          color: var(--color-red);
        }
        &,
        .time,
        .price,
        .location,
        .dot {
          color: #aaa;
        }
      }
    }
  }
  .btn.top {
    background-color: var(--color-blue-dk);
    color: white;
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 74px;
    white-space: normal;
    z-index: 1;
    .arrow-top {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      display: block;
      height: 0;
      margin: 5px auto;
      width: 0;
    }
  }
  a {
    color: black;
    .title {
      text-decoration: underline;
      color: var(--color-blue-link);
    }
  }
}
</style>
