<template>
  <div class="event">

    <Loader v-if="!page" />
    <div v-else class="full-width">
      <section class="hero gradient">
          <div class="container centered">
              <h1 class="text-bold text-white">{{page.name}}</h1>
          </div>
      </section>
      <section class="event-info">
        <div class="container">
          <div class="card-group row pt-5">
            <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="page.startDate">
              <div class="card-body">
                <i class="far fa-calendar circle-bg"></i><span>Date and time</span>
                <p class="card-text">{{ page.startDate }}</p>
                <p class="card-text">{{ page.startTime }} - {{ page.endTime }}</p>
              </div>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="page.price">
              <div class="card-body">
                <i class="fas fa-dollar-sign circle-bg"></i><span>Price</span>
                <p class="card-text">{{page.price}}</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="page.tips">
              <div class="card-body">
                <i class="fas fa-smile-wink circle-bg"></i><span>Tips</span>
                <p class="card-text">{{page.tips}}</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="page.floor">
              <div class="card-body">
                <i class="fas fa-chess-board circle-bg"></i><span>Floor type</span>
                <p class="card-text">{{page.floor}}</p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="page.flyerImage">
              <div class="card-body">
                <i class="fas fa-align-left circle-bg"></i><span>View flyer</span>
                <p class="card-text"><a :href="page.flyerImage" target="_black">open flyer</a></p>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-8" v-if="page.locationName">
              <div class="card-body">
                <i class="fas fa-location-arrow circle-bg"></i><span>Location</span>
                <p class="card-text">{{page.locationName}}<br/>{{page.locationAddress}}</p>
              </div>
            </div>
          </div>
          <!-- <p class="card-text text-right"><small class="text-muted">Last updated 3 mins ago</small></p> -->
        </div>
      </section>
      <hr class="divider">
      <section class="event-about outer">
        <div class="container inner">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <h2 class="d-inline-block mb-2 mb-3">About {{page.name}}</h2>
                <p class="card-text mb-auto">{{page.description}}</p>
                <!-- <p class="card-text mb-auto mt-3" v-if="!showMoreText"> -->
                <p class="card-text mb-auto mt-3">
                  <span v-if="page.organizer"><strong>Organizer:</strong> {{page.organizer}}</span> <br/>
                  <a v-if="page.website" :href="page.website" target="_blank"><i class="fas 2x fa-globe"></i></a>
                  <a v-if="page.facebook" :href="page.facebook" target="_blank"><i class="fab 2x fa-facebook-square"></i></a>
                  <a v-if="page.instagram" :href="page.instagram" target="_blank"><i class="fab 2x fa-instagram"></i></a>
                </p>
                <!--<div class="btn btn-outline-custom btn-md btn-lg mt-5" v-on:click="showMoreText=!showMoreText">{{(showMoreText)?'Learn more':'Less'}}</div> -->
              </div>
              <div class="col-md-6 text-center">
                <img class="img-fluid" alt="Placeholder" style="width: 500px; height: auto;" src="../assets/images/salsa-dancing.jpg">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _filter from 'lodash/filter'
import utils from '@/utils'
import Loader from '@/components/Loader.vue'
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'eventPages',
    ]),
    id () {
      return this.$route.params.id
    },
    page () {
      return _filter(this.eventPages, { 'eventId': this.id })[0]
    },
  },
  components: {
    Loader
  },
  data() {
    return {
      showMoreText: true
    }
  },
  name: 'eventPage',
  methods: {
    ...mapMutations([
      'setEvents',
      'setEventPages'
    ]),
    async loadEvents() {
      if(this.eventPages.length > 0) return
      // Classes online tab
      const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?gid=0&single=true&output=tsv'

      // Calendar Tab
      //const url='https://docs.google.com/spreadsheets/d/e/2PACX-1vTR9ZjomDfGtIbUjVGARlUG8Ac9JOkHrq3Q5DHft2RH7N35-_qL6fHJdHI4TY6vqXo0r7vz6VZNAuVc/pub?output=tsv'
      const that = this;
      return await axios
        .get(url)
        .then(function(response) {
           var json = utils.tsvToJSON(response.data)
           that.setEvents(json)
           that.setEventPages(json)
        })
        .catch(function() {
           that.setEvents([])
           that.setEventPages([])
        })
    }
  },
  async mounted() {
    await this.loadEvents();

    // if the page doesn't exist redirect to calendar
    if (!this.page) {
      this.$router.push({name:'home'})
    }
  },
  metaInfo() {
    var title = (this.page)?this.page.name:'Salsa New York Calendar | Best salsa events in NY'
    var description = (this.page)?this.page.description:'Follow this event on SalsaNewYork.com'
    return {
      title: title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        }
      ]
    }
  }
}



</script>

<style scoped lang="scss">

  .event > .full-width > .gradient {
    position: relative;
    height: 500px;
    background: url('../assets/images/salsa-dancing.jpg') center center no-repeat;
    background-size: cover;
    opacity: 1;

    &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000428;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #004e92, #000428);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #004e92, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity: 0.6;
    animation: fadeIn 5s ease;
    }

    @keyframes fadeIn{
      0%{
        opacity: 1;
      }
      100%{
        opacity: 0.6;
      }
    }

  }

  @media only screen and (min-width: 700px) {
    .event > .full-width > .gradient   {
      height: 500px;
    }
  }

  @media only screen and (min-width: 1025px) {
    .event > .full-width > .gradient   {
      height: 100vh;
    }
    .event-about img {
        margin-top: 0em !important;
      }
  }
  .hero {
    position: relative;
    z-index: 10;
    text-align: center;
    padding-top: 60px;
  }
  .centered {
    position:absolute;
    top:50%;
    left:50%;
    text-align:center;
    transform: translateX(-50%) translateY(-50%);
  }
  .circle-bg {
      background: #cbe4ec;
      width: 50px;
      height: 50px;
      text-align: center;
      vertical-align: middle;
      padding: 17px;
      border-radius: 50%;
  }
  .event {
    h1 {
        font-family: var(--default-font);
        font-weight: 600;
        font-size: 3rem;
      }
    .card-body p {
        font-family: 'Raleway', sans-serif;
        margin-left: 4em;
    }
    i.far {color: var(--color-blue);}
    i.fas {color: var(--color-blue);}
  }
  .event-info {
    background-color: #f5f5f5;
    padding: 3em 0em 5em 0em;
    span {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        padding: 10px;
      }
      p {
        margin-bottom: 0.5rem;
      }
  }
  .event-about {
    padding: 5em 0em;
    i {
      font-size: 2em;
      color: #3333ff;
      padding: 5px 5px;
    }
    h2 {
        font-size: 22px;
        font-weight: 600;
        padding: 0px;
        color: blue;
      }
    img {
      margin-top: 3em;
      box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    }
  }
  .divider {
      border-top-width: 2px!important;
      border-color: #FF731D;
      width: 3rem;
      margin-top: 5em;
  }

</style>
