<template>
  <div :class="['calendar',{'padding-topnav':isTop}]">
    <div :class="['header',{'sny-fixed-top':isTop}]">
      <calendar-nav
        :title="navTitle"
        :showBackBtn="isPast"
        :disableTextBtn="isThisMonth"
        type="calendar"
        @next="nextMonth"
        @prev="prevMonth"
        @now="now">
      </calendar-nav>

      <ul class="weekdays">
        <li>Sun</li>
        <li>Mon</li>
        <li>Tue</li>
        <li>Wed</li>
        <li>Thu</li>
        <li>Fri</li>
        <li>Sat</li>
      </ul>
    </div>

    <div class="body">
      <day v-for="day in this.daysOfMonth"
        :day="day"
        :isToday="isToday(day)"
        :isOld="isOld(day)"
        :title="dayTitle(day)"
        :events="monthEvents[day]"
        :staticEvents ="monthEvents['24_7']"
        :inactive="!isInCurrentMonth(monthMMMM,day)"
        :data="day"
        :key="day"
        class="day">
      </day>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Day from '@/components/Day.vue'
import CalendarNav from '@/components/CalendarNav.vue'


export default {
  components: {
    Day, CalendarNav
  },
  computed: {
    currentDate() {
      return {
        moment: moment(this.selectedDate),

      }
    },
    isThisMonth() {
      return moment().format("YYYYMM") === this.currentDate.moment.format("YYYYMM")
    },
    isTop() {
      return this.scrollTop > 430
    },
    isPast() {
      return moment().format("YYYYMM") < this.currentDate.moment.format("YYYYMM")
    },
    month() {
      return this.currentDate.moment.format("MM");
    },
    monthEvents() {
      var temp = {};

      // Create empty matrix with the days of the current month
      for(var i in this.daysOfMonth) {
        temp[this.daysOfMonth[i]] = [];
      }
      temp["24_7"] = []

      var date = "";

      // Push events in corresponding day
      for (i in this.events) {
        date = (this.events[i]).startDate

        if (date != "24_7") {
          // Remove the time of the start date 2020-04-18 19:00
          date = moment.utc(date, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD")
        }
        if (date && temp[date]) {
          temp[date].push(this.events[i])
        }
      }
      return temp
    },
    monthMMMM() {
      return this.currentDate.moment.format("MMMM");
    },
    navTitle() {
      return this.monthMMMM + " " + this.year;
    },
    year() {
      return this.currentDate.moment.format("YYYY");
    },
  },
  data() {
    return {
      selectedDate: new Date(),
      daysOfMonth:[],
      dateFormat: "YYYY-MM-DD",
    }
  },
  methods: {
    generateDaysOfMonth(month, year) {
      // Reset current month
      this.daysOfMonth = [];
      var startMonth = moment(year + '-' + month + '-01');
      var startNextMonth = moment(startMonth).add(1,'month');
      var daysInMonth = startMonth.daysInMonth();

      // Push end of last month
      for (var i=startMonth.day(); i>0; i--) {
        this.daysOfMonth.push(
          moment(startMonth).subtract(i,"days").format(this.dateFormat)
        );
      }
      // Push current month
      for(i=1; i<=daysInMonth; i++) {
        this.daysOfMonth.push(
          moment(year + '-' + month + '-' + ((i<10)?'0'+i:i) ).format(this.dateFormat)
        );
      }

      // Push beginning of next month
      var lastDay = startMonth.endOf("month").day();
      for (i=lastDay; i<=5; i++) {
        this.daysOfMonth.push(
          moment(startNextMonth).add(i-lastDay,"days").format(this.dateFormat)
        );
      }
    },
    isInCurrentMonth(monthMMMM, dateYYYYMMMMDD) {
      return this.month === dateYYYYMMMMDD.substring(5,7);
    },
    isToday(day) {
      return moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
    },
    isOld(day) {
      return moment(day).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
    },
    nextMonth() {
      this.selectedDate = moment(this.selectedDate).endOf("month").add(1,"days")
      this.generateDaysOfMonth(this.selectedDate.format("MM"), this.selectedDate.format("YYYY"))
    },
    now() {
      this.selectedDate = moment(new Date());
      this.generateDaysOfMonth(this.selectedDate.format("MM"), this.selectedDate.format("YYYY"))
    },
    prevMonth() {
      this.selectedDate = moment(this.selectedDate).startOf("month").subtract(1,"days")
      this.generateDaysOfMonth(this.selectedDate.format("MM"), this.selectedDate.format("YYYY"))
    },
    dayTitle(day) {
      return "" + parseInt(day.slice(-2), 10)
    }
  },
  mounted() {
    this.generateDaysOfMonth(this.month, this.year);
  },
  name: 'Calendar',
  props: {
    events: Array,
    scrollTop: Number,
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
.padding-topnav {
  padding-top: 82px;
}
.calendar {
  margin: 0 1rem;
  .weekdays {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      border-left: 1px solid var(--color-gray-dk);
      border-bottom: 1px solid var(--color-gray-dk);
      padding: 5px;
      width: calc(100% / 7);
      &:last-child {
        border-right: 1px solid var(--color-gray-dk);
      }
    }
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    & > div {
      font-size: 12px;
      width: calc(100% / 7);
      padding: 5px;
    }
    .day {
      border-bottom: 1px solid var(--color-gray-dk);
      border-left: 1px solid var(--color-gray-dk);
      min-height: 70px;
      &:nth-of-type(7n),
      &:last-child {
        border-right: 1px solid var(--color-gray-dk);
      }
    }
  }
  .header,
  .body {
    background: var(--color-gray-lt);
  }
  .today {
    h5 {
      background-color: var(--color-orange);
      border-radius: 50%;
      color: white;
      display: inline-block;
      padding: 4px 9px;
    }
  }
  .day {
    h5 {
      font-size: 16px;
      &.float {
        top: 54px;
        margin-left: 130px;
        position: fixed;
        z-index: 99;
      }
    }
    &.old,
    &.inactive {
      h5 {
        color: var(--color-gray-dk);
      }
    }
    &.old {
      a {
        display: none;
      }
    }
    &.inactive {
      a {
        opacity: 0.5;
      }
    }
  }

  .event {
    background: white;
    border-radius: 3px;
    border: 1px solid var(--color-gray-dk);
    border-left: 3px solid var(--color-blue-dk);
    color: var(--color-blue-link);
    display: block;
    padding: 8px 5px;
    &:not(:first-child) {
      margin-top: 5px;
    }
    .time {
      color: var(--color-blue-dk);
      font-weight: 700;
      margin-right: 3px
    }
    .price,
    .location {
      color: var(--color-gray-muted);
      font-size:11px;
    }
    .price {
      text-transform: uppercase;
    }
    .location {
      text-transform: capitalize;
    }
    .dot:before { content: "  \00b7  "; }

    &.cancelled {
      border-left: 3px solid var(--color-gray-dk);
      &::after{
        content:"cancelled";
        display: block;
        text-transform: uppercase;
        font-weight: bold;
      }
      .time,
      .price,
      .location,
      .dot {
        color: #666;
      }
    }

    &.is24_7 {
      border-left: 3px solid var(--color-blue-link);
      &::after{
        content:"24/7";
        display: block;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
</style>
